//Toggling of services search field from header/nav
(function($) {
  var body = document.body;
  var header = document.querySelector('#site-header');
  var searchLink = header.querySelector('.nav__menu__item-find_services');
  searchLink.classList.add('search-services__trigger');
  var searchTrigger = header.querySelectorAll('.search-services__trigger');
  var searchEl = header.querySelector('.search-services-wrapper');
  var searchInput = searchEl.querySelector('input#s');
  Array.prototype.slice.call(searchTrigger).forEach(function(el) {
    el.addEventListener('click', function(e) {
      e.preventDefault();
      searchEl.classList.toggle('active');
      body.classList.toggle('overlay-active');
      if (searchEl.classList.contains('active')) searchInput.focus();
    });
  });

  searchInput.addEventListener('input', function(e) {
    if (searchInput.value && searchInput.value.length >= 3) {
      searchInput.closest('#searchform').classList.add('has-autosuggest');
    } else {
      searchInput.closest('#searchform').classList.remove('has-autosuggest');
    }
  });

  var searchForm = document.querySelector('.page-controls #searchform');
  if (searchForm) {
    //Focus on search field if arriving on search page with blank query
    (function() {
      var searchInput = searchForm.querySelector('.search-field');
      if (searchInput) {
        if (!searchInput.value || !searchInput.value.length) {
          document.addEventListener('DOMContentLoaded', function(e) {
            searchInput.focus();
          });
        }
      }
    })();
    //Autosubmit search when query fields change
    (function() {
      var searchFilters = searchForm.querySelectorAll('select');
      Array.prototype.slice.call(searchFilters).forEach(function(el) {
        el.addEventListener('change', function(e) {
          searchForm.submit();
        });
      });
    })();
    //Allow cancelling filters individually
    (function() {
      var searchFiltersSelected = searchForm.querySelectorAll(
        '.filter-selected'
      );
      Array.prototype.slice.call(searchFiltersSelected).forEach(function(el) {
        relatedFilter = searchForm.querySelector(
          '[name=' + el.getAttribute('rel') + ']'
        );
        el.addEventListener('click', function(e) {
          el.parentElement.removeChild(el);
          relatedFilter.value = null;
          searchForm.submit();
        });
      });
    })();
  }
})(jQuery);
